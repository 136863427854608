import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ComSpecBlockType, PieGraphItem } from 'src/app/model/commodities-graph';
import { NavigationOptions } from 'src/app/model/queryParams';
import { VerticalGraph, VerticalGraphItem } from 'src/app/model/vertical-views';

type userSelectionData = {
  pieIndex: number;
  data: any;
}

/**
 * @description
 * This component acts as a container for businesss groups (commodities and sepcialties) activity graphs.
 * It handles the connection between pie graphs, detailed vertical bar graph and finanicial data table, according to the sellected business group.
 */
@Component({
  selector: 'app-com-spec-slide',
  templateUrl: './com-spec-slide.component.html',
  styleUrls: ['./com-spec-slide.component.scss']
})
export class ComSpecSlideComponent {
  @Input() data: any = null;
  @Input() navInfo: NavigationOptions = {} as NavigationOptions;
  @Input() isLoading: boolean = true;
  @Output() currentUserSelectedData = new EventEmitter<userSelectionData>();

  @Input() set userData(data: any) {
    this._userData = data;
    if (data) {
      if (data.pieIndex || data.pieIndex === 0) {
        this.selectedPieIndex = data.pieIndex;
      }
      if (data.data && data.data.colName && this.data) {
        const currentGraphIndex = this.data.graph.graphs[this.selectedPieIndex].data.findIndex(
          (item: any) => item.colName === data.data.colName
        )
        if (currentGraphIndex > -1 && this.selectedColoumnIndex !== currentGraphIndex) {
          this.onSelectGarphValue(currentGraphIndex);
        }
      }
    }
  }
  get userData(): any {
    return this._userData;
  }

  // public selectedBusinessGroup: string = ComSpecBlockType[0];

  public selectedPieIndex: number = 0;
  public selectedColoumnIndex!: number;
  public currentVerticalGraph: VerticalGraph | null = null;
  public currentPieGraph: PieGraphItem[] = [];
  public types: any = ComSpecBlockType;

  private _userData: any;


  constructor(private cdr: ChangeDetectorRef) { }

  public handleGraphChange() {
    this.selectedPieIndex = this.data.graph.defaultPieGraphIndex ?? 0;
    //select default graph column
    this.data.graph.graphs[this.selectedPieIndex].data.forEach((item: VerticalGraphItem, index: number) => {
      if (item.isDefault) {
        this.selectedColoumnIndex = index;
      }
    });

    if(this.userData) {
      if(this.userData.pieIndex || this.userData.pieIndex === 0) {
        this.selectedPieIndex = this.userData.pieIndex;
      }
      if(this.userData.data) {
        const colIndex =  this.data.graph.graphs[this.selectedPieIndex].data.findIndex(
          (item: any) => item.colName === this.userData.data.colName
        )
        if(colIndex > -1) {
          this.selectedColoumnIndex = colIndex;
        }
      }
    }
  }


  ngOnInit(): void {

  }

  public selectBusinessGroup(businessGroup: PieGraphItem): void {
    this.selectedPieIndex = businessGroup.title.toLowerCase() === 'commodities' ? 0 : 1;
    // this.currentVerticalGraph =this.data.graph.graphs[this.selectedPieIndex];
    this.currentUserSelectedData.emit({
      pieIndex: this.selectedPieIndex,
      data: this.userData ? this.userData.data : null
    });
    this.cdr.detectChanges();
  }


  public onSelectGarphValue(columnIndex: number): void {
    this.selectedColoumnIndex = columnIndex;
    // this.currentPieGraph =this.data.graph.pieGraphs[columnIndex].data;
    this.cdr.detectChanges();
  }

  public onUserBarSelect(data: any): void {
    this.currentUserSelectedData.emit({
      pieIndex: this.selectedPieIndex,
      data: data
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['data']) {
      this.handleGraphChange();
    }

  }
}
