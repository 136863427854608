import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';
import {environment} from '../../environments/environment'

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent {
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

public version = environment.version;

  public launch(): void {
    if (this.authorizationService.isLoggedIn()) {
      this.router.navigate(['dashboard']);
    } else {
      this.authorizationService.login();
    }
  }
}
