import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, take } from 'rxjs';
import { Divisions, GraphContainer } from '../model/generic-graph';
import { NavigationOptions } from '../model/queryParams';
import { HttpService } from './http.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadableState } from '../model/loadable-state';
import { environment } from 'src/environments/environment';

/**
 * This service controls the navigation between divisions and fetching their graphs data.
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {
  public divisionData: string[];
  private dataSubject: BehaviorSubject<LoadableState<GraphContainer[]> | null> = new BehaviorSubject<LoadableState<GraphContainer[]> | null>(null);
  public data$: Observable<LoadableState<GraphContainer[]> | null> = this.dataSubject.asObservable();
  private currentDivisionData: any = {};

  /**
   * @property {Observable<NavigationOptions>} currentPath holds current query params
   */
  public currentPath = this.activatedRoute.queryParams.pipe(map((queryParams: Params) => {
    const qParams : NavigationOptions = queryParams;
    
    return {
      div: qParams.div ? qParams.div : Divisions.ICL,
      graph: qParams.graph,
      nav: qParams.nav ? +qParams.nav : undefined,
      slide: qParams.slide ? +qParams.slide : undefined,
      column: qParams.column ? +qParams.column: undefined,
      bu: qParams.bu,
      buSubtitle: qParams.buSubtitle,
      dropdown: qParams.dropdown,
      sbu: qParams.sbu,
    }
  }));

  constructor(private httpService: HttpService, private activatedRoute : ActivatedRoute, private router: Router) {
    this.divisionData = Object.values(Divisions);
  }

  public selectDivision(div: string): void {
    const queryParams: Params = { div };
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams, queryParamsHandling: 'merge' });
  }

  public setGraph(graph: string, shouldScroll: boolean = true): void {
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { graph: null } , queryParamsHandling: 'merge'});
    setTimeout(() => this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { graph }, queryParamsHandling: 'merge' }));
  }

  public async setDataByDivision(division: string) {
    this.dataSubject.next({
      isLoading: true
    });

    if(environment.useApi) {
      //skip page load if data is already loaded
      if(this.currentDivisionData[division]) {
        setTimeout(() => {
          this.dataSubject.next({
            isLoading: false,
            data: this.currentDivisionData[division]
          });
        },0);
      return;
      }
      //load current page, then proceed to load the rest.
      this.httpService.getDirect<GraphContainer[]>(environment.baseUri + 'graphs?div=' + division).pipe(take(1)).subscribe(data => {
        //save division data
        this.currentDivisionData[division] = data;
        this.dataSubject.next({
          isLoading: false,
          data: data
        });
        //load remaining divs
        this.divisionData.forEach((div) => {
          if(div !== division) {
            this.httpService.getDirect<GraphContainer[]>(environment.baseUri + 'graphs?div=' + div).pipe(take(1)).subscribe(data => {
              this.currentDivisionData[div] = data;
            });
          }
        });
      });
      //request new data every 5 minutes
      setInterval(() => {
        this.divisionData.forEach((div) => {
          this.httpService.getDirect<GraphContainer[]>(environment.baseUri + 'graphs?div=' + div).pipe(take(1)).subscribe(data => {
            this.currentDivisionData[div] = data;
          });
        });
      }, 300000);
    } else {
      const { dataMock }: any = await import('../mock/data.mock');
      setTimeout(()=> {
        this.dataSubject.next({
          isLoading: false,
          data: dataMock[division]
        });
      }, 1);
    }
  }
}