import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { GraphContainer } from 'src/app/model/generic-graph';
import { IntersectionState } from '../section-layout/section-layout.component';

@Component({
  selector: 'app-commodities-specialties-view',
  templateUrl: './commodities-specialties-view.component.html',
  styleUrls: ['./commodities-specialties-view.component.scss']
})
export class CommoditiesSpecialtiesViewComponent {

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() graphData: GraphContainer = {} as GraphContainer;
  public isLoading: boolean = true;

  public userData: any;

  public onUserSelectedData(data: any): void {
    this.userData = data;
    this.cdr.detectChanges();
  }

  public onIntersectionChange(event: IntersectionState): void {
    if(event === IntersectionState.Inside) {
      this.isLoading = false;
    }
  }
}
