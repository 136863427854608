import { Graph, GraphColumn } from "./generic-graph";

/**
 * Atomic Graph Part - Holds data for all types of vertical graphs
 */
export interface VerticalGraph {
  columnType: String; //for example M for millions, etc will show as 62M
  data: VerticalGraphItem[];

}

export interface VerticalGraphItem extends GraphColumn {
  partial?: VerticalGraphColumn;
  current: VerticalGraphColumn;
  compare?: VerticalGraphColumn;
  isPreliminary?: boolean;
  isDefault?: boolean;
  isFuture?: boolean;
  colName: String;
  colGroupName: String;
  cif?: number; //relevant only in potash avg
  fob?: number; //relevant only in potash avg
}

export interface VerticalGraphColumn {
  value: number;
  percent?: number;
}


/**
 * Atomic Graph Part - Holds data for summary batteries in vertical graphs etc.
 */
export interface SummaryBattery {
  colNames: String[]; //in order - for example ['ACT', 'LM']
  columns: SummaryBatteryItem[];
}

export interface SummaryBatteryItem {
  title: String;
  value: number;
  columns: number[]; //in order according to outer array
  negative?: boolean;
  size?: number;
  abs?: number;
}


/**
 * graph view (for example sales, oi, ebitda)
 */
export interface VerticalGraphView extends Graph {
  data: VerticalGraphViewData[]; //in main graph - only 1 element in array.
  isMainGraph: boolean;
}

export interface VerticalGraphViewData {
  title: String; //for example "Total, ADS etc"
  graph: VerticalGraph;
  battery: SummaryBattery[]; // same order as graph columns
}


/**
 * Potash Avg price
 */

export interface PotashAveragePriceView extends Graph {
  data: VerticalGraph;
}

/**
 * EPS graph view
 */

export interface EPSGraphView extends Graph {
  data: VerticalGraph;
  footer: EPSDataItem[]; //notice - array for each column in vertical graph, array item for ni columns

}

export interface EPSDataItem {
  dilutedShares: number; //fixed 2 point decimal
  adjustedNI: number; //fixed 2 point decimal
  dilutedSharesCompare: number; //fixed 2 point decimal
  adjustedNICompare: number; //fixed 2 point decimal
}

/**
 * NI Graph view
 */

export interface NIGraphView extends Graph {
  data: VerticalGraph;
}


export enum VerticalGraphType {
  SIMPLE_BAR,
  COMPARE_BAR,
}






