import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

/**
 * @description
 * This service controls the user authentication process, using @see AdalService.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {

  constructor(
    private oauthService: OAuthService,
    private router: Router
  ) {
    this.oauthService.configure({
      ...environment.adalConfig,
      redirectUri: window.location.origin + '/postauth',
    });
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    // this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public isLoggedIn(): boolean {
    if (localStorage.getItem('sso.idtoken') != null) return true;
    return this.oauthService.hasValidAccessToken();
  }

  public async login(): Promise<boolean> {
    if (this.isLoggedIn())
    {
      this.router.navigate(['postauth']);
      return true;
    }
    else
    {
      const isLoggedIn = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (isLoggedIn)
      {
        // this.oauthService.setupAutomaticSilentRefresh();
        return true;
      }
      else {
        this.oauthService.initImplicitFlow();
        return isLoggedIn; //not reachable - flow should redirect to login page
      }
    }
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  public getToken(): string{
    if(localStorage.getItem('sso.idtoken') != null){
      return localStorage.getItem('sso.idtoken')!;
    }
    return this.oauthService.getAccessToken();
  }
}
