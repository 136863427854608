import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent {
  constructor(private authorizationService: AuthorizationService, private router: Router) {}

  isLoggedIn(): boolean {
    return this.authorizationService.isLoggedIn();
  }

  ngOnInit(): void {
    if (this.isLoggedIn()) {
      this.router.navigate(['dashboard']);
    } else {
      this.authorizationService.login().then(() => {
        if(this.isLoggedIn()) {
          this.router.navigate(['dashboard']);
        } else {
          console.error('User is not logged in');
        }
      }).catch((error) => {
        console.error('Error while logging in', error);
      });
    }
  }
}
