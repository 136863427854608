import { Component, Input } from '@angular/core';
import { SummaryBatteryItem } from 'src/app/model/vertical-views';

@Component({
  selector: 'app-battery',
  templateUrl: './battery.component.html',
  styleUrls: ['./battery.component.scss'],
})
export class BatteryComponent {

  private _item: SummaryBatteryItem = {} as SummaryBatteryItem;
  @Input() isMainGraph: boolean = false;
  @Input() isTwoColumns: boolean = true;
  @Input() sbuBattery: boolean = false;
  @Input() div: string = '';
  @Input() columnNames: String[] = [];

  @Input()
  set item(newItem: SummaryBatteryItem) {
    if (newItem) {
      this._item = {
        ...newItem,
        negative: newItem.value < 0,
        size: Math.abs(newItem.value) < 100 ? Math.abs(newItem.value) + 1 : 100,
        abs: Math.abs(newItem.value)
      };
    }
  }
  get item(): SummaryBatteryItem {
    return this._item;
  }

  public beforeInit = false; 


  public getLeftOffset(value: number): number {
    const numericValue = Number(value)
    let left = 0;
    const maxNumber = numericValue >= 100 ? 65 : 72 //looks bad if number is 3 letters
    const numberToDecrease = numericValue <= -10 ? 30 : 23 
    if(numericValue >= 0){
      left = numericValue + 5
    }else if(numericValue < 0){
      left = 100 + (numericValue) - numberToDecrease
    }
    return Math.min(Math.max(left, 3), maxNumber);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.beforeInit = true;
    },50);
  }
}
