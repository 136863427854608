<ng-container *ngIf="data">
    <ul class="financial-table" [ngClass]="{'compare-view': isCompare}">
        <li class="rows">
            <span class="title"></span>
            <span class="actual">{{data.rowNames[0]}}</span>
            <span class="compare" *ngIf="data.rowNames[1]">{{data.rowNames[1]}}</span>
        </li>

        <li *ngFor="let block of data.blocks" class="block"
            [ngClass]="{
                'commodity': block.type === 'commodities',
                'specialty': block.type === 'specialties',
                'selected': block.type === (selectedType === 0 ? 'commodities' : 'specialties')
            }">
            <span class="title">{{block.title}}</span>
            <span class="actual">{{block.fields[0]}}</span>
            <span class="compare" *ngIf="block.fields[1] != null">{{block.fields[1]}}</span>
        </li>
    </ul>
</ng-container>
