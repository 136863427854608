import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SplashComponent } from './splash/splash.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { dashboardGuard } from './guards/dashboard.guard';

const routes: Routes = [
  { path: '', component: SplashComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [dashboardGuard]
  },
  { path: 'postauth', component: LoadingPageComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
