<div class="batteryRow" [ngClass]="{'hidePointer': !isMainGraph, 'sbuBattery': sbuBattery }">
  <span class="title" >{{item.title}}</span>
  <span class="column" *ngFor="let name of columnNames; let i = index">{{item.columns[i]}}</span>
  <span class="value" *ngIf="item.value !== undefined && item.value !== null" [ngClass]="{'grid-col-5': !isMainGraph}">
    <div class="value-container" [ngClass]="{ 'negative':item.negative}" >
      <div class="value-data" [style.width.%]="beforeInit ? item.size : 0" >&nbsp;</div>
        <!-- TODO: replace % with pipe -->
        <span class="value-text" [ngStyle]="{'left': getLeftOffset(item.value)+ '%'}">
          {{item.abs}}%
          </span>
      </div>
    </span>
    <div *ngIf="isMainGraph" class="pointer" >&rsaquo;</div>
</div>
